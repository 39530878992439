import "plyr-react/plyr.css";
import React, { Component, useRef, useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import johnHeader from '../images/IndexHeaders/f3b.png'
import gardenHeader from '../images/IndexHeaders/Garden.png'
import valkyrieHeader from '../images/IndexHeaders/Project Valkyrie.png'
import shenanigansHeader from '../images/IndexHeaders/Shenanigans.png'
import sierraHeader from '../images/IndexHeaders/Sierra Bell.png'
import sierraVertical from '../images/verticals/Sierra Bell.png'
import valkyrieVertical from '../images/verticals/Project Valkyrie.png'
import shenanigansVertical from '../images/verticals/Shenanigans.png'
import johnVertical from '../images/verticals/John Coday.png'
import gardenVertical from '../images/verticals/GardenWolf.png'
import dizzyVertical from '../images/verticals/DizzyDummyBoy.png'
import zmVertical from '../images/verticals/ZM_Art.png'
import sierrabell from '../images/thumbnails/sierrabell.png'
import valkyrie from '../images/thumbnails/valkyrie.png'
import kaikaku from '../images/thumbnails/kaikaku.png'
import shenanigans from '../images/thumbnails/shenanigans.png'
import dmn7 from '../images/thumbnails/dmn7.png'
import labrat from '../images/thumbnails/labrat.png'
import johncrandom from '../images/thumbnails/johncrandom.png'
import frog from '../images/thumbnails/frog.png'
import jltt from '../images/thumbnails/JLTT.png'
import dungeon from '../images/thumbnails/dungeon.jpg'
import dungeonHeader from '../images/IndexHeaders/DungeonRelease.png'
import dungeonVertical from '../images/verticals/Maple_Promo_Art.jpg'
import Slider from "react-slick";
import '../stylesheets/home.css'
import {Helmet} from "react-helmet";

function Home(){
  const carouselOptions = {
    dots: true,
    infinite: true,
    arrows: false,
    lazyLoad: true,
    accessibility: true
  }

  const smallerCarousel = {
    dots: false,
    infinite: false,
    arrows: false,
    lazyLoad: true,
    accessibility: true
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (windowSize[0] < 900){
  return (
    <div className="Home">
      <Helmet>
      <meta
      name="description"
      content="We are Mythos Makers! We create Comics, Video Games, Videos, and Podcasts."
    />
        <title>Mythos Makers</title>
      </Helmet>
      <div style={{height: "60px"}} ></div>
      <div className="sliderContainer">
        <Slider {...carouselOptions} className="MainPageSlider">
          <div class="headerImageContainer" >
            <a href="/games/shenanigans">
              <img src={shenanigansHeader} className="headerImageMobile" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/dungeon">
              <img src={dungeonHeader} className="headerImageMobile" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/sierra-bell">
              <img src={sierraHeader} className="headerImageMobile" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/valkyrie">
              <img src={valkyrieHeader} className="headerImageMobile" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="https://www.youtube.com/@F3B_Podcast">
              <img src={johnHeader} className="headerImageMobile" ></img>
            </a>
          </div>
        </Slider>
        
      </div>
      <br />
      <div>
        <h1>
          FEATURED FRANCHISES
        </h1>
        <Slider {...smallerCarousel} className="blocks">
          <a href="/comics/sierra-bell">
            <img src={sierraVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/comics/valkyrie">
            <img src={valkyrieVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/games/shenanigans">
            <img src={shenanigansVertical} className="vertical-thumbnail" alt="" />
          </a>
        </Slider>
      </div>
      <div>
        <h1>
          FEATURED COMICS
        </h1>
        <Slider {...smallerCarousel} className="blocks">
          <a href="/comics/sierra-bell"><img src={sierrabell} alt="" class="thumbnail" /></a>
          <a href="/comics/dungeon"><img src={dungeon} alt="" class="thumbnail" /></a>
          <a href="/comics/valkyrie"><img src={valkyrie} alt="" class="thumbnail" /></a>
        </Slider>
      </div>
      <div>
        <h1>
          FEATURED GAMES
        </h1>
        <Slider {...smallerCarousel} className="blocks">
        <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
        <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
        <a href="/games/labrat"><img src={labrat} alt="" class="thumbnail" /></a>
        </Slider>
      </div>
      <div>
        <h1>
          FEATURED VIDEOS
        </h1>
        <Slider {...smallerCarousel} className="blocks">
        <a href="/videos/johncoday"><img src={johncrandom} alt="" class="thumbnail" /></a>
        <a href="/videos/johnlikestotalk"><img src={jltt} alt="" class="thumbnail" /></a>
        <a href="/videos/frenchfrog"><img src={frog} alt="" class="thumbnail" /></a>
        </Slider>
      </div>
      <div>
        <h1>
          FEATURED CREATORS
        </h1>
        <Slider {...smallerCarousel} className="blocks">
          <a href="/creators/johncoday">
            <img src={johnVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/gardenwolf">
            <img src={gardenVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/dizzydummy">
            <img src={dizzyVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/zm">
            <img src={zmVertical} className="vertical-thumbnail" alt="" />
          </a>
        </Slider>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}else{
  return (
    <div className="Home">
      <div style={{height: "60px"}} ></div>
      <div className="sliderContainer">
        <Slider {...carouselOptions} className="MainPageSlider">
          <div class="headerImageContainer" >
            <a href="/games/shenanigans">
              <img src={shenanigansHeader} class="headerImage" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/dungeon">
              <img src={dungeonHeader} class="headerImage" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/sierra-bell">
              <img src={sierraHeader} class="headerImage" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/comics/valkyrie">
              <img src={valkyrieHeader} class="headerImage" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/creators/johncoday">
              <img src={johnHeader} class="headerImage" ></img>
            </a>
          </div>
          <div class="headerImageContainer" >
            <a href="/creators/gardenwolf">
              <img src={gardenHeader} class="headerImage" ></img>
            </a>
          </div>
        </Slider>
        
      </div>
      <br />
      <div>
        <h1>
          FEATURED FRANCHISES
        </h1>
        <div className="blocks">
          <a href="/comics/sierra-bell">
            <img src={sierraVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/comics/valkyrie">
            <img src={valkyrieVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/games/shenanigans">
            <img src={shenanigansVertical} className="vertical-thumbnail" alt="" />
          </a>
        </div>
      </div>
      <div>
        <h1>
          FEATURED COMICS
        </h1>
        <div className="blocks">
          <a href="/comics/sierra-bell"><img src={sierrabell} alt="" class="thumbnail" /></a>
          <a href="/comics/dungeon"><img src={dungeon} alt="" class="thumbnail" /></a>
          <a href="/comics/valkyrie"><img src={valkyrie} alt="" class="thumbnail" /></a>
        </div>
      </div>
      <div>
        <h1>
          FEATURED GAMES
        </h1>
        <div className="blocks">
        <a href="/games/shenanigans"><img src={shenanigans} alt="" class="thumbnail" /></a>
        <a href="/games/dmn7"><img src={dmn7} alt="" class="thumbnail" /></a>
        <a href="/games/labrat"><img src={labrat} alt="" class="thumbnail" /></a>
        </div>
      </div>
      {/* <div>
        <h1>
          FEATURED VIDEOS
        </h1>
        <div className="blocks">
          <a href="/videos/johncoday"><img src={johncrandom} alt="" class="thumbnail" /></a>
          <a href="/videos/johnlikestotalk"><img src={jltt} alt="" class="thumbnail" /></a>
          <a href="/videos/frenchfrog"><img src={frog} alt="" class="thumbnail" /></a>
        </div>
      </div> */}
      <div>
        <h1>
          FEATURED CREATORS
        </h1>
        <div className="blocks">
          <a href="/creators/johncoday">
            <img src={johnVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/gardenwolf">
            <img src={gardenVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/dizzydummy">
            <img src={dizzyVertical} className="vertical-thumbnail" alt="" />
          </a>
          <a href="/creators/zm">
            <img src={zmVertical} className="vertical-thumbnail" alt="" />
          </a>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
}

export default Home;
